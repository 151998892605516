import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "current react “base” stack",
  "author": "tzookb",
  "type": "post",
  "date": "2018-11-08T09:13:48.000Z",
  "url": "/2018/11/current-react-base-stack/",
  "categories": ["javascript"],
  "tags": ["javascript", "react"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This post is mainly for myself and my co-workers to have a reference about the current react stack possibilities.`}</p>
    <p>{`CSS & Styling:`}</p>
    <ul>
      <li parentName="ul">{`styled-components`}</li>
      <li parentName="ul">{`Emotion`}</li>
    </ul>
    <p>{`Routers:`}</p>
    <ul>
      <li parentName="ul">{`react-router`}</li>
      <li parentName="ul">{`reach-router`}</li>
    </ul>
    <p>{`Form Handling:`}</p>
    <ul>
      <li parentName="ul">{`Formik`}</li>
      <li parentName="ul">{`final form`}</li>
    </ul>
    <p>{`testing:`}</p>
    <ul>
      <li parentName="ul">{`enzyme`}</li>
      <li parentName="ul">{`jest`}</li>
    </ul>
    <p>{`immutability:`}</p>
    <ul>
      <li parentName="ul">{`immutable`}</li>
      <li parentName="ul">{`seamless-immutable`}</li>
      <li parentName="ul">{`immer`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      